'use client';

import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import type { CheckTaxIdOutputFlow } from '@monorepo/interfaces';
import { MagicLinkSendMethod } from '@monorepo/api/clients/sortingHatSdk/types';
import { usePostEvents } from '@monorepo/events';
import { useRouter } from '@monorepo/router';
import { cpfRegex, isValidCPFWithMod11 } from '@monorepo/utils/isValidCPF';

import { trpc } from '@src/server/trpc/react';

type SchemaType = {
  taxId: string;
};

const flowToUrl: Record<CheckTaxIdOutputFlow, string> = {
  first_access: '/primeiro-acesso',
  password: '/senha',
  create_password: '/criar-senha',
  first_access_with_email: '/primeiro-acesso/email',
  first_access_with_phone: '/primeiro-acesso/sms',
  update_contact: '/atualizacao-contato',
  no_access: '/sem-acesso',
  no_sorting_hat_user: '/atualizacao-contato/email?flow=all',
};

export const useSendTaxIdForm = () => {
  const t = useTranslations('signInPage');
  const tToast = useTranslations('toast');
  const router = useRouter();

  const { mutate: deleteTaxId } = trpc.auth.deleteTaxId.useMutation();

  const { mutate: checkUserByTaxId, isPending: isCheckUserByIdLoading } =
    trpc.auth.checkUserByTaxId.useMutation();
  const { mutate: sendMagicLink, isPending: isSendMagicLinkLoading } =
    trpc.auth.sendMagicLink.useMutation();
  const { mutate: checkAuthUserDataRedis } =
    trpc.redis.checkAuthUserData.useMutation();
  const { mutate: setAuthUserDataRedis } =
    trpc.redis.setAuthUserData.useMutation();
  const { mutate: postEvent } = usePostEvents();

  const postEventAccessBlockedError = () => {
    postEvent({
      app: 'auth',
      event: 'Access_Blocked',
      scope: 'login',
      action: 'component_view',
    });
  };

  const schema = z.object({
    taxId: z
      .string()
      .regex(cpfRegex, { message: t('invalidTaxId') })
      .refine((data) => isValidCPFWithMod11(data), {
        message: t('invalidTaxId'),
      }),
  });

  const form = useForm<SchemaType>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    context: undefined,
  });

  const {
    formState: { errors },
    watch,
  } = form;

  React.useEffect(() => {
    deleteTaxId();
  }, [deleteTaxId]);

  const onSubmit = form.handleSubmit(({ taxId }: SchemaType) => {
    checkAuthUserDataRedis(undefined, {
      onSuccess: () => {
        checkUserByTaxId(
          { taxId },
          {
            onSuccess: (data) => {
              if (
                ![
                  'first_access_with_email',
                  'first_access_with_phone',
                ].includes(data.flow)
              )
                return router.push(flowToUrl[data.flow]);

              sendMagicLink(
                {
                  sendMethod:
                    data.flow === 'first_access_with_email'
                      ? MagicLinkSendMethod.email
                      : MagicLinkSendMethod.sms,
                  type: 'firstAccess',
                },
                {
                  onSuccess: () => router.push(flowToUrl[data.flow]),
                  onError: () => router.push(flowToUrl.first_access),
                }
              );
            },
            onError: () => {
              setAuthUserDataRedis();
              return router.push('/sem-acesso');
            },
          }
        );
      },
      onError: () => {
        postEventAccessBlockedError();
        toast.error(tToast('attemptLimit'));
      },
    });
  });

  return {
    form,
    onSubmit,
    isLoading: isCheckUserByIdLoading || isSendMagicLinkLoading,
    taxIdValid: watch('taxId')?.length === 14 && !errors.taxId,
  };
};
