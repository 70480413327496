'use client';

import React from 'react';
import { Button } from '@gravity/button';
import { Text } from '@gravity/text';
import { useTranslations } from 'next-intl';
import { ControlledFormField, Form, Spinner } from '@monorepo/tailwind-ui/components';
import { Link } from '@monorepo/tailwind-ui/src/components/Link';
import { cn } from '@monorepo/tailwind-ui/utils';
import { FixedWrapper } from '@src/components/FixedWrapper';
import { useSendTaxIdForm } from './useSendTaxIdForm';
export default function TaxIdForm() {
  const t = useTranslations('signInPage');
  const {
    form,
    onSubmit,
    isLoading,
    taxIdValid
  } = useSendTaxIdForm();
  return <Form {...form} data-sentry-element="Form" data-sentry-component="TaxIdForm" data-sentry-source-file="index.tsx">
      <form onSubmit={onSubmit} className="flex h-full flex-col justify-between md:gap-6">
        <div className="flex flex-col gap-4">
          <ControlledFormField id="taxId" control={form.control} name="taxId" type="string" variant="primary" inputMode="numeric" mask="cpf" placeholder={t('enterTaxId')} data-sentry-element="ControlledFormField" data-sentry-source-file="index.tsx" />

          <Text className="text-gray-600" data-sentry-element="Text" data-sentry-source-file="index.tsx">{t('rfDescription')}</Text>
        </div>

        <FixedWrapper data-sentry-element="FixedWrapper" data-sentry-source-file="index.tsx">
          <Text variant="caption-regular" className="mb-4 text-gray-600" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {t.rich('termsAndPrivacy', {
            terms: chunks => <Link href="https://meu.olaisaac.io/termo" className="text-blue-600" underline="auto" size={1} weight="light" target="_blank">
                  {chunks}
                </Link>,
            privacy: chunks => <Link href="https://isaac.com.br/declaracao-de-privacidade" className="text-blue-600" underline="auto" size={1} weight="light" target="_blank">
                  {chunks}
                </Link>
          })}
          </Text>

          <Button className={`gap-6 ${isLoading ? 'text-transparent disabled:text-transparent' : ''}`} fullWidth type="submit" disabled={!taxIdValid} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <Spinner className={`${isLoading ? 'absolute flex' : 'hidden'}`} data-sentry-element="Spinner" data-sentry-source-file="index.tsx" />
            {t('continue')}
          </Button>
        </FixedWrapper>
        <div className={cn('absolute inset-0 mb-11 hidden h-dvh w-full bg-white opacity-40', isLoading && 'block')} />
      </form>
    </Form>;
}